<template lang="pug">
.box_size(:style="kidsMode ? 'border: 2px solid darkgray; margin-top: 10px; padding-bottom: 100px; width: 85mm; position: relative' : ''")
  img(style="position: absolute; bottom: 0; left: 0; width: 100%; opacity: 0.6; z-index: -1;" :src="bg" v-if="kidsMode")
  div(style="transform: scale(0.6); filter: brightness(0);")
    Header
  h3.center-text.mt-2(style="font-size: 1rem;"): strong Belaire {{ siteName }}
  .mt-1.mb-4(style="display: flex; gap: 10px; align-items: center; justify-content: center;")
    h5.center-text.m-0(style="font-size: 0.8rem;") {{ date }}
    .line(style="width: 1px; height: 15px; background-color: black;")
    h5.center-text.m-0(style="font-size: 0.8rem;") {{ hour }}
  h4.center-text.pt-3.mb-0(style="font-size: 1.5rem; line-height: 1.5rem;"): strong {{ tramite }}
  h2.center-text(style="font-size: 2rem;"): strong {{ turn }}
  h5.center-text(v-if="!particular" style="font-size: 1rem; line-height: 1.2rem; text-transform: capitalize; max-width: 122px; margin: 0 auto;") Juan Jose Zinuga {{ currentUserName }}
  h5.center-text(v-else style="font-size: 0.7rem; font-style: italic; line-height: 1rem; text-transform: capitalize; max-width: 122px; margin: 0 auto;") Los centros médicos Colmédica son operados por la UMD
</template>

<script>
import Header from "./components/Header";
import moment from "moment";
import bg from "@/assets/images/bg.png";

export default {
  name: "TicketPrint",

  components: {
    Header
  },

  data() {
    return {
      bg
    };
  },

  computed: {
    turn() {
      return this.$route.query.turn;
    },

    tramite() {
      return this.$route.query.tramite;
    },

    date() {
      return moment().format("DD/MM/YYYY");
    },

    hour() {
      return moment()
        .format("hh:mm a")
        .replace("am", "a.m.")
        .replace("pm", "p.m.");
    },

    branch() {
      return this.$route.query.branch;
    },

    queue() {
      return this.$route.query.queue;
    },

    siteName() {
      return this.$route.query?.sitename;
    },

    currentUserName() {
      return this.$route.query?.username;
    },

    kidsMode() {
      return this.$route.query?.kidsmode;
    },

    particular() {
      return this.$route.query?.particular;
    }
  }
};
</script>

<style scoped>
.center-text {
  text-align: center;
}
.box_size {
  width: 71mm;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  text-align: center;
  padding: 0 1.5rem;
  padding-bottom: 2.5rem;
}
</style>
